import './contacto.css'
import dimasoftOffice from '../../../Dimasoft/oficina-providencia-dimasoft.png'
import { FaEnvelope, FaMapMarkerAlt, FaRegClock } from 'react-icons/fa'
// import { Footer} from '../Footer/Footer'
import { FirstHeader, SecondHeader} from '../Header/Headers'
import { LeftButtons} from '../LeftButtons/ContactButtons'
import { useState } from "react"
import Reaptcha from 'reaptcha';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../MetaTags/TitleDescriptions';

const tokenTelegram = '1234283731:AAHXyWzXdyxXguYi6aRyDgSyehp0D4DNZjo'
const chatIdTelegram = '-922772120'


export function Contacto(){
    
    return <div className='containerContacto'>
        <TitleDescription title='Contacto - Dimasoft' description='Optimiza la gestión de tu negocio con nuestro software líder en el mercado. Descubre cómo nuestros sistemas de gestión y punto de venta pueden impulsar tus ventas en chile y simplificar tus operaciones.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <img className='dimasoftOffice' src={dimasoftOffice} alt="dimasoftOffice" />
        <div className='contacto'>
            <h1>Contáctenos</h1>
            <div className='line gradient line-medium' data-aos="fade-right"></div>
            <div className='contacto-content'>
                <div className='contacto-content-left'>
                    <FormContact direccion={true}/>
                </div>
                <div className='contacto-content-right'>
                    <h3>Información</h3>
                    <p>Si tiene alguna duda o consulta, contáctenos,</p>
                    <p>+56 22 205 1018</p>
                    <p><FaMapMarkerAlt/> Pocuro #2378, Of. 201, Providencia, Santiago</p>
                    <p><FaEnvelope/> info@dimasoft.cl</p>
                    <br />
                    <h3>Horario de atención</h3>
                    <p><FaRegClock/> Lunes a viernes - 08:30 a 18:30</p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.569106420139!2d-70.60412048495256!3d-33.434476580778444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf7445f0047f%3A0xce5d82281fd8f5c4!2sAv.%20Pocuro%202378%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1675820043972!5m2!1ses!2scl" style={{border:0, width:'100%', height: '400px'}} loading="lazy"/>
                </div>
            </div>
        </div>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}

export function FormContact(props){

    function sendMessage(message){
        const url = `https://api.telegram.org/bot${tokenTelegram}/sendMessage`;
        
        const obj = {
            chat_id: chatIdTelegram,
            text: message
        };
        const xht = new XMLHttpRequest();
        xht.open("POST", url, true);
        xht.setRequestHeader("Content-type", "application/json; charset=UTF-8");
        xht.send(JSON.stringify(obj));
                
        let formulario = document.getElementById('formul');
        formulario.reset();
        MySwal.fire({
            title: 'Mensaje enviado correctamente.',
            icon: 'success',
            confirmButtonColor: '#3085d6'
        })
    }

    const [values, setValues] = useState({
        nombre: "",
        rut: "",
        empresa: "",
        direccion: "",
        telefono: "",
        mail: "",
        asunto: "",
        mensaje: "",
    });

    function handleSubmit(event) {
        event.preventDefault()
        let msg = `Nombre: ${values.nombre}\nRut: ${values.rut}\nEmpresa: ${values.empresa}\nDireccion: ${values.direccion}\nTelefono: ${values.telefono}\nMail: ${values.mail}\nAsunto: ${values.asunto}\nMensaje: ${values.mensaje}`
        sendMessage(msg);
    }

    const handleChange=(event)=>{

        setValues({
            ...values,
            [event.target.name] : event.target.value
        });
    }

    //// reCaptcha
    const [verified,setVerified] = useState(false)
    const onVerify = recaptchaResponse => {
        setVerified(true)
    };
    const onExpire = ()=>{
        setVerified(false)
    };

    //// sweetAlert
    const MySwal = withReactContent(Swal)

    return <form className='formulario-contacto' onSubmit={handleSubmit} id="formul">
        <div className="input-group">
            <label className="label-input">
                <input type='text' required onChange={handleChange} name="nombre"/>
                <span className={values.nombre?"span-input has-value":"span-input"}>Nombre <span className='input-required'>*</span></span>
            </label>
        </div>
        {/* {props.rut?<div className="input-group">
            <label className="label-input">
                <input type='text' required onChange={handleChange} name="rut"/>
                <span className={values.rut?"span-input has-value":"span-input"}>RUT <span className='input-required'>*</span></span>
            </label>
        </div>:''} */}
        <div className="input-group">
            <label className="label-input">
                <input type='text' required onChange={handleChange} name="empresa"/>
                <span className={values.empresa?"span-input has-value":"span-input"}>Empresa <span className='input-required'>*</span></span>
            </label>
        </div>
        <div className="input-group">
            <label className="label-input">
                <input type='text' required onChange={handleChange} name="telefono"/>
                <span className={values.telefono?"span-input has-value":"span-input"}>Teléfono <span className='input-required'>*</span></span>
            </label>
        </div>
        <div className="input-group">
            <label className="label-input">
                <input required type="email" onChange={handleChange} name="mail"/>
                <span className={values.mail?"span-input has-value":"span-input"}>Correo electrónico <span className='input-required'>*</span></span>
            </label>
        </div>
        <div className="input-group">
            <label className="label-input">
                <input type='text' onChange={handleChange} name="direccion"/>
                <span className={values.direccion?"span-input has-value":"span-input"}>Dirección</span>
            </label>
        </div>
        <div className="input-group">
            <label className="label-input">
                <input type="text" onChange={handleChange} name="asunto"/>
                <span className={values.asunto?"span-input has-value":"span-input"}>Asunto</span>
            </label>
        </div>
        <div className="input-group">
            <label className="label-input" id='contactoMsg'>
                <input type="text" onChange={handleChange} name="mensaje"/>
                <span className={values.mensaje?"span-input has-value":"span-input"}>Mensaje</span>
            </label>
        </div>
        <Reaptcha sitekey="6LezP78kAAAAANEpIGEg2X6I0GtNg7ujALA_97pV" onVerify={onVerify} onExpire={onExpire}/>
        <button className={verified?'contactoButton':'contactoButton contact-button-disabled'} type="submit" disabled={!verified}>Enviar</button>
    </form>

}