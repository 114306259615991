import './remuneraciones.css'
import { Footer} from '../../Footer/Footer'
import { FirstHeader, SecondHeader} from '../../Header/Headers'
import { LeftButtons} from '../../LeftButtons/ContactButtons'
import image1 from '../../../../Others/remuneraciones-dimasoft.jpg'
import { Contactenos } from '../../Contactenos/Contactenos'

import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../../MetaTags/TitleDescriptions';

export function RemuneracionesR(){
    return <div className='containerRemuneraciones'>
        <TitleDescription title='Sistema, Programa. Software de Remuneraciones - Dimasoft' description='Software de Remuneraciones Chile. Sistema de Remuneraciones genera Liquidaciones de Sueldo, Contratos, Anexos, Certificados y Finiquitos gestionados con Firma Electronica.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <h1>Remuneraciones v8.0</h1>
        <Remuneraciones/>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}
export function Remuneraciones(){
    return <div className='remuneraciones'>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gf-content-flex'>
            <div className='left-content' data-aos="fade-right">
                <p>El software de Remuneraciones V8.0 de Dimasoft se encuentra disponible para nuestros clientes en dos formatos de uso:</p>
                <ul>
                    <li>Versión Licencia – Red Área Local.</li>
                    <li>Versión Cloud – Pago Mensual.</li>
                </ul>
            </div>
            <img src={image1} alt="" />
        </div>
        <h2>Características:</h2>
        <div className='gf-content' data-aos="fade-right">
            <ul>
                <li>Software Multiempresa, puede manejar hasta 999 empresas en línea</li>
                <li>Generación de Liquidaciones de Sueldo.</li>
                <li>Libro de Remuneraciones Electronico. ( Nueva Normativa Octubre 2021 ).</li>
                <li>Formulario 1887, emisión de Certificados de Impuesto Renta, de acuerdo a la Res. Nº 6836 del S.I.I. (D.O. 06/01/94), incluye generación de archivos para transmisión de datos vía INTERNET al S.I.I.</li>
                <li>Contratos, Anexos, Certificados, Finiquitos emitidos y gestionados con Firma Electronica. ( Nueva Normativa Octubre 2021 ).</li>
                <li>Enlace eficiente con herramientas como Excel, Word, PDF</li>
                <li>Parametrizado</li>
                <li>Cargas Masivas de Anticipos, Haberes, Horas Extras, otros.</li>
                <li>Perfiles de Usuarios</li>
                <li>Genera archivos para enviar a PreviRed</li>
                <li>Genera Archivos para enviar Anticipos y Sueldos a Bancos</li>
                <li>Cuenta Corriente Trabajador.</li>
                <li>Administración de Vacaciones.</li>
                <li>Nueva Ley de Costos días Domingo.</li>
                <li>Informes de Control.</li>
                <li>Informe comparativo periodo actual / Periodo anterior por Trabajador</li>
                <li>Avisos términos de Contratos, Termino de Seguro Cesantía, Saldo Crédito, otros…</li>
                <li>Sin Límite de Trabajadores.</li>
                <li>Actualizaciones Permanentes según cambios legales.</li>
            </ul>
        </div>
        <h2>Precios</h2>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gf-content' data-aos="fade-right">
            <p><b>Precios Versión Licencia:</b> Desde $290.000.- + Iva.</p>
            <p><b>Precios Versión Cloud:</b> Desde 1 UF + Iva.   1 Usuario / Mes  ( Facturación mensual ).</p>
            <ul >
                <li>Multiempresa.</li>
                <li>Soporte Telefónico Permanente incluido</li>
                <li>Capacitación Inicial Incluida</li>
                <li>Habilitación Inmediata</li>
                <li>Respaldo de Datos incluido.</li>
            </ul>
        </div>
        <h2>Contáctenos</h2>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gf-content'>
            <Contactenos/>
        </div>
    </div>
}