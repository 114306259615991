import './facturaElectronica.css'
import { Footer} from '../../Footer/Footer'
import { FirstHeader, SecondHeader} from '../../Header/Headers'
import { LeftButtons} from '../../LeftButtons/ContactButtons'
import image1 from '../../../../Others/factura-dimasoft.jpg'
import image2 from '../../../../Others/SII-dimasoft.png'
import dteCompras from '../../../../Others/Dte-de-compras-dimasoft-4.png'
import { Contactenos } from '../../Contactenos/Contactenos'

import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../../MetaTags/TitleDescriptions';

export function FacturaElectronicaR(){
    return <div className='containerFactura'>
        <TitleDescription title='Factura Electrónica, Software de Facturacion Electronica - Dimasoft' description='Software de Facturacion Electronica Chile. Sistema de Factura Electrónica no tiene cobro por Documento Emitido, maneja y administra tus folios, Diseño grafico de su Documento Tributario Electronico, Generacion en formato carta, media carta o Ticket.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <h1>Factura Electrónica</h1>
        <FacturaElectronica/>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}
export function FacturaElectronica(){
    return <div className='factura'>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gf-content' data-aos="fade-right">
            <p>El Servicio de Facturación Electrónica o DTEs, actualmente integrado con el Software de Gestión Comercial v7.1,  Pos- Punto de Venta v7.1, Gestión Ferreteria v7.1 y Gestión Taller Automotriz v7.1, permite emitir todos los Documentos Tributarios Electrónicos (DTEs).</p>
        </div>
        <div className='gf-content-flex'>
            <img src={image1} alt="" />
            <div className='right-content' data-aos="fade-left">
                <h3><b>Características:</b></h3>
                <ul>
                    <li>Sin cobro por Documento Emitido.</li>
                    <li>Apoyo en el proceso de enrolamiento ante SII, para obtener la resolución de emisor Electrónico.</li>
                    <li>Obtención de Firma Electrónica.</li>
                    <li>Envío Automatizado del DTE al SII.</li>
                    <li>Envío Automatizado de respuestas a Proveedores.</li>
                    <li>Almacenamiento de Documentos.</li>
                    <li>Manejo y administración de folios.</li>
                    <li>Diseño gráfico de su Documento Tributario Electronico.</li>
                    <li>Generación del DTE en formato carta, media carta o Ticket.</li>
                    <li>Visualizador para documentos en formato PDF.</li>
                    <li>Soporte Técnico permanente vía telefónica, email o conexión remota.</li>
                </ul>
            </div>
        </div>
        <h2>DTE's DE COMPRAS</h2>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gf-content'>
            <img className='imageCompras' src={dteCompras} alt="" />
        </div>
        <h2>Etapas de Certificación con SII.</h2>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gf-content-flex'>
            <div className='left-content' data-aos="fade-right">
                <ol>
                    <li>Set de Pruebas.</li>
                    <li>Simulación.</li>
                    <li>Envío de muestras de impresión.</li>
                    <li>Intercambio de información entre contribuyentes.</li>
                    <li>Declaración de cumplimiento de requisitos.</li>
                    <li>Resolución del SII que autoriza al contribuyente como operario de DTE.
                        </li>
                </ol>
            </div>
            <img src={image2} alt="" />
        </div>
        <h2>Documentos Electrónicos</h2>
        <div className='line gradient line-small'></div>
        <div className='gf-content'>
            <table data-aos="fade-right">
                <tbody>
                    <tr>
                        <td>Basicos:</td>
                        <td>
                            <ul>
                                <li>Factura Electrónica</li>
                                <li>Factura Exenta Electrónica</li>
                                <li>Nota de Crédito Electrónica</li>
                                <li>Nota de Débito Electrónica</li>
                                <li>Guías de Despacho Electrónica</li>
                            </ul>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>Opcionales:</td>
                        <td>
                            <ul>
                                <li>Boleta Electrónica. ( Formato carta, media carta o Ticket ).</li>
                                <li>Factura de Terceros Electrónica.</li>
                                <li>Factura de Exportación Electrónica.</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h2>Contáctenos</h2>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gf-content'>
            <Contactenos/>
        </div>
    </div>
}