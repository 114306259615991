import './acerca.css'
import acercaImage from '../../../Dimasoft/oficina-dimasoft.png'
import { Footer} from '../Footer/Footer'
import { FirstHeader, SecondHeader} from '../Header/Headers'
import { LeftButtons} from '../LeftButtons/ContactButtons'
import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../MetaTags/TitleDescriptions';

export function Acerca(){

    return <div className='containerAcerca'>
        <TitleDescription title='Acerca - Dimasoft' description='Conoce acerca de nosotros, nuestros inicios y nuestro enfoque.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <div className='acerca'>
            <h1>Acerca de nosotros</h1>
            <div className='line gradient line-medium' data-aos="fade-right"></div>
            <div className='acercaContent'>
                <div className='acercaText' data-aos="fade-right">
                    <p>
                        Empresa 100% Chilena, que inicia sus actividades el año 1992, orientándose al servicio de desarrollo de software en el ámbito comercial, para entregar soluciones a pequeñas y medianas empresas, de manera que pudieran contar con información completa y oportuna, condición indispensable para desarrollarse en mercados dinámicos y competitivos.
                    </p>
                    <p>
                        Dimasoft Ltda. ha ganado la confianza de sus clientes a lo largo del tiempo debido al compromiso de colaboración, el cual consiste en brindar soporte y asesoría que permitan mantener la vigencia de sus sistemas, ya sea en el ámbito comercial como también tributario.
                    </p>
                    <p>
                        Uno de los pilares fundamentales de Dimasoft Ltda. ha sido mantener una línea de trabajo transparente que brinde tranquilidad y seguridad a nuestros clientes.
                    </p>
                </div>
                <img src={acercaImage} alt="Office" data-aos="zoom-in"/>
            </div>
            <div className='line line-dark'></div>
            <h3>Antecedentes Generales</h3>
            <table data-aos="fade-up">
                <tbody>
                    <tr>
                        <td>Razón social:</td>
                        <td>Informática Dimasoft Ltda.</td>
                        
                    </tr>
                    <tr>
                        <td>Giro:</td>
                        <td>Desarrollo y comercialización de software</td>
                        
                    </tr>
                    <tr>
                        <td>Dirección:</td>
                        <td>Pocuro #2378, Oficina 201</td>
                    </tr>
                    <tr>
                        <td>Comuna:</td>
                        <td>Providencia</td>
                    </tr>
                    <tr>
                        <td>Ciudad:</td>
                        <td>Santiago</td>
                    </tr>
                    <tr>
                        <td>Teléfono:</td>
                        <td>(+56) 22 205 1018</td>
                    </tr>
                    <tr>
                        <td>Email:</td>
                        <td>ventas@dimasoft.cl</td>
                    </tr>

                </tbody>
            </table>
        </div>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}