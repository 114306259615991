import './inicio.css'
import { FirstHeader, SecondHeader} from '../Header/Headers'
import { LeftButtons } from '../LeftButtons/ContactButtons'
import { Slider } from './Slider/Slider'
import { Numbers } from './Numbers/Numbers'
import { Informacion } from './Informacion/Informacion'
import { Ventajas } from './Ventajas/Ventajas'
import { AlgunosClientes } from './AlgunosClientes/AlgunosClientes'
import { Socios } from './Socios/Socios'

import * as React from 'react';
import { useLocation, useNavigate, ScrollRestoration  } from "react-router-dom";
import { TitleDescription } from '../MetaTags/TitleDescriptions';


export function Inicio(){
    const navigate = useNavigate();

    let location = useLocation();
    let url = location.pathname.split('/')[1]

    React.useEffect(()=>{
        if(url.length!=0){
            navigate("/");
        }
    },[])

    return <div className='containerInicio'>
        <TitleDescription title='Dimasoft – Software de gestión comercial – Administración de negocios – Contabilidad – Chile'/>
        <FirstHeader/>
        <SecondHeader change={true}/>
        <LeftButtons/>
        <div className='inicio'>
            <Slider />
            <Numbers/>
            <Informacion/>
            <Ventajas/>
            <AlgunosClientes/>
            <Socios/>
        </div>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}
