import './serviciosti.css'
import { Link, ScrollRestoration } from "react-router-dom";
import { Footer} from '../../Footer/Footer'
import { FirstHeader, SecondHeader} from '../../Header/Headers'
import { LeftButtons} from '../../LeftButtons/ContactButtons'
// import ayudaTecnicaImg from '../../../../ServiciosTI/ayuda_tecnica-dimasoft.png'
import ayudaTecnicaImg from '../../../../ServiciosTI/ayuda-tecnica-dimasoft.jpg'
import levantamientoProyectoImg from '../../../../ServiciosTI/levantamiento-proyecto-dimasoft.png'
import continuidadOperacionalImg from '../../../../ServiciosTI/continuidad-operacional-dimasoft.png'
import redesLocalesImg from '../../../../ServiciosTI/redes-locales-dimasoft.jpg'
import equiposComputacionImg from '../../../../ServiciosTI/equipos-computacion-dimasoft.png'
import impresorasImg from '../../../../ServiciosTI/impresoras-dimasoft.png'
import windowsLogoImg from '../../../../ServiciosTI/logo-windows-dimasoft.png'
import seguridadImg from '../../../../ServiciosTI/seguridad-dimasoft.jpg'
import respaldosImg from '../../../../ServiciosTI/respaldos-dimasoft.jpg'
import antivirusImg from '../../../../ServiciosTI/antivirus-dimasoft.jpg'
import activeDirectory from '../../../../ServiciosTI/active-directory-dimasoft.png'
import basesDeDatos from '../../../../ServiciosTI/bases-de-datos-dimasoft.jpg'
import emailVendors from '../../../../ServiciosTI/email-vendors-dimasoft.jpg'

import { TitleDescription } from '../../MetaTags/TitleDescriptions';


export function ServciosTIR(){
    return <div className='containerServiciosTi'>
        <TitleDescription title='Servicios de Soporte Tecnologico, Software y Hardware - Dimasoft' description='Brindamos consultoria sobre nuestros Softwares en general, tambien reviciones de las conexiones y configuraciones.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <h1>Servicios TI</h1>
        <div className='serviciosti-content'>
            <ServiciosTI/>
        </div>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}
export function ServiciosTI(){
    return <div className='serviciosti'>
        <div className='line gradient line-small'></div><br />
        <h2>SOPORTE GENERAL EN HERRAMIENTAS DE SOFTWARE DE DIMASOFT</h2>
        <div className='g-content-flex'>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={ayudaTecnicaImg} alt="ayudaTecnicaImg" />
            </div>
            <div className='g-content-60' data-aos="fade-left">
                <p>Capacitaciones, con respecto a las Herramientas de Software de Dimasoft, así como también el servicio de consultoría en general sobre los mismos, se realizan revisiones de las conexiones y configuraciones de los softwares brindando soporte presencial a la empresa con respecto a las fallas o problemas que puedan presentar.</p>
            </div>
            
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <h2>LEVANTAMIENTO DE REQUERIMIENTO</h2>
        <div className='g-content-flex'>
            <div className='g-content-60' data-aos="fade-right">
                <p>Revisión física y lógica de los recursos informáticos de la empresa para determinar y recomendable que trabajen de forma cloud o forma local las herramientas de Software de Dimasoft, con la finalidad de brindar asesoría y optimización de recursos para el mejor desempeño de los mismos y la gestión de la empresa.</p>
            </div>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={levantamientoProyectoImg} alt="levantamientoProyectoImg" />
            </div>
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <h2>CONTINUIDAD OPERACIONAL</h2>
        <div className='g-content-flex'>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={continuidadOperacionalImg} alt="continuidadOperacionalImg" />
            </div>
            <div className='g-content-60' data-aos="fade-left">
                <p>Instalación, cambio de servidores o migraciones de sistemas de forma paralela, es decir, en conjunto con la empresa para garantizar la continuidad operativa de la misma y así no detener los procesos productivos y/o logísticos.</p>
            </div>
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <h2>REVISIÓN, INSTALACIÓN Y SOPORTE EN REDES DE ÁREA LOCAL</h2>
        <div className='g-content-flex'>
            <div className='g-content-60' data-aos="fade-right">
                <p>Revisión de la Red de Área Local de cada empresa para verificar si es óptima para la utilización de las herramientas de software, se brinda asesoría con respecto a las mejoras que se podrían realizar para optimizar los procesos de uso compartidos y políticas de Usuarios, si la empresa carece de Redes Locales se realiza el levantamiento correspondiente y luego una instalación e implementación de la Red.</p>
            </div>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={redesLocalesImg} alt="redesLocalesImg" />
            </div>
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <div className='g-content-flex'>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={equiposComputacionImg} alt="equiposComputacionImg" />
            </div>
            <div className='g-content-60' data-aos="fade-left">
                <h3>REVISIÓN E INSTALACIÓN DE EQUIPOS DE COMPUTACIÓN</h3>
                <p>Revisión físicas y lógicas de los Equipos de computación de la empresa para garantizar que estos sean compatibles con las características mínimas para utilizar los Softwares de Dimasoft. Con esto se garantiza que los equipos cumplan con las características mínimas, además garantizamos la optimización de los procesos, de igual manera si la empresa lo requiere se instalan los equipos que la empresa tenga para utilizar con el sistema.</p>
                <h3>REVISIÓN Y REPARACIÓN DE SOFTWARE Y HARDWARE</h3>
                <p>Así como la revisión de los Equipos informáticos también se hace la reparación de los mismos brindándole a las empresas soluciones informáticas integrales, se pueden realizar mantenimientos preventivos y correctivos de los Equipos como cambio de Hardware o Limpieza de Software.</p>
            </div>
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <h2>INSTALACIÓN DE IMPRESORAS (LASER, INYECCIÓN, TICKETS)</h2>
        <div className='g-content-flex'>
            <div className='g-content-60' data-aos="fade-right">
                <p>Instalación de Impresoras Ticket distribuidas por Dimasoft, así como también la Instalación y configuración en el Sistema de Impresoras láser o inyección dispuestas por la empresa para realizar sus operaciones.</p>
            </div>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={impresorasImg} alt="impresorasImg" />
            </div>
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <h2>ADMINISTRACIÓN DE SISTEMA OPERATIVO</h2>
        <div className='g-content-flex'>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={windowsLogoImg} alt="windowsLogoImg" />
            </div>
            <div className='g-content-60' data-aos="fade-left">
                <p>Análisis, asesoría e instalación de sistemas operativos tanto en equipos de trabajo como Servidores. Siempre buscando la manera más optima de utilizar los recursos de informática de la empresa.</p>
            </div>
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <h2>PROTECCIÓN DE DATOS</h2>
        <div className='g-content-flex'>
            <div className='g-content-60' data-aos="fade-right">
                <p>Sabemos que los datos de la empresa son importantes, por lo cual siempre se solicitara autorización de la empresa para realizar cualquier copia de Bases de Datos para correcciones o Revisiones.</p>
            </div>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={seguridadImg} alt="seguridadImg" />
            </div>
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <h2>RESPALDO CORPORATIVO DE INFORMACIÓN</h2>
        <div className='g-content-flex'>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={respaldosImg} alt="respaldosImg" />
            </div>
            <div className='g-content-60' data-aos="fade-left">
                <p>Se realiza instalación de Sistema de Respaldo para que las bases de datos de las empresas tengan un Backup en nuestro servidor por cualquier problema o falla de sus Servidores.</p>
            </div>
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <h2>REVISIÓN E INSTALACIÓN DE ANTIVIRUS</h2>
        <div className='g-content-flex'>
            <div className='g-content-60' data-aos="fade-right">
                <p>Para brindar mayor protección a la información se realizan revisiones de los equipos con antivirus para eliminar cualquier software malicioso que evite la conexión y correcto funcionamiento del Sistema, así como evitar la infección de las bases de datos de la empresa.</p>
            </div>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={antivirusImg} alt="antivirusImg" />
            </div>
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <h2>ADMINISTRACIÓN DE ACTIVE DIRECTORY</h2>
        <div className='g-content-flex'>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={activeDirectory} alt="activeDirectory" />
            </div>
            <div className='g-content-60' data-aos="fade-left">
                <p>Se realizan sesiones de Usuario a Nivel Cloud y de escritorio Remoto, así como también la configuración de las conexiones locales de Red en el Servidor de la empresa.</p>
            </div>
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <h2>ADMINISTRACIÓN DE BASE DE DATOS</h2>
        <div className='g-content-flex'>
            <div className='g-content-60' data-aos="fade-right">
                <p>Instalación y configuración de conexión a la base de datos de la empresa, se realizan tareas de búsquedas o aplicación de sentencias en base de datos según las necesidades de la empresa. De igual manera cualquier problema o error que las bases de datos llegasen a presentar serán corregidas o puestas en revisión, se realizaran las migraciones de datos si es necesario.</p>
            </div>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={basesDeDatos} alt="basesDeDatos" />
            </div>
        </div><br /><br />
        <div className='line gradient line-small'></div><br />
        <h2>CORREO ELECTRÓNICO</h2>
        <div className='g-content-flex'>
            <div className='g-content-40' data-aos="zoom-in">
                <img src={emailVendors} alt="emailVendors" />
            </div>
            <div className='g-content-60' data-aos="fade-left">
                <p>Conexiones y verificaciones de correos electrónicos para configurar Factura Electrónica y dejar registro de estos correos en el Portal Web del SII, sin embargo, también se pueden resolver problemas de conexión con algunos correos corporativos.</p>
            </div>
        </div><br /><br />


        <div className='g-content content-center'>
            <h2 className='span-contact'>Contáctenos</h2>
            <div className='line gradient line-small'></div>
            <p>Solicite más información, conversemos de su negocio...</p>
            <Link to="/contacto"><button className='secondary-button'>Contáctenos</button></Link>
        </div>
    </div>
}