import React, {useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { createBrowserRouter,RouterProvider,useNavigate } from "react-router-dom";

import { Footer } from './Components/Footer/Footer'
import { FooterGrupoDCT } from './Components/Footer/FooterGrupoDCT'

import { Inicio } from './Components/Inicio/Inicio'
import { Acerca } from "./Components/Acerca/Acerca"
import { Software } from "./Components/Software/Software"
import { GComercialR } from "./Components/Software/GestionComercial/GComercial"
import { POSAdvanceR } from "./Components/Software/POSAdvance/PosAdvance"
import { GFerreteriasR } from "./Components/Software/GestionFerreterias/GFerreterias"
import { ContabilidadR } from "./Components/Software/Contabilidad/Contabilidad"
import { RemuneracionesR } from "./Components/Software/Remuneraciones/Remuneraciones"
import { FacturaElectronicaR } from "./Components/Software/FacturaElectronica/FacturaElectronica"
import { BoletaElectronica } from "./Components/Software/BoletaElectronica/BoletaElectronica"
import { Ecommerce} from "./Components/Software/Ecommerce/Ecommerce"

import { Servicios } from "./Components/Servicios/Servicios"
import { CapacitacionesR } from "./Components/Servicios/Capacitaciones/Capacitaciones"
import { ServciosTIR as ServiciosTI } from "./Components/Servicios/ServiciosTI/ServiciosTI"
import { ContableAuditoriaR as ContableAuditoria } from "./Components/Servicios/ContableAuditoria/ContableAuditoria"

import { Clientes } from "./Components/Clientes/Clientes"
import { Contacto } from "./Components/Contacto/Contacto"
import { KitPuntoVentaR } from "./Components/Products/KitPuntoVenta"
import { PoliticaDevoluciones } from "./Components/PoliticaDevoluciones/PoliticaDevoluciones"

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({duration:600})


//// Funcion de cambio de url
function ChangeUrl({to}){
  const navigate = useNavigate();
  useEffect(()=>{
        navigate(to);
  },[])
}


const router = createBrowserRouter([
  {
    path: '/*',
    element: <Inicio />,
  },
  {
    path: '/acerca',
    element: <Acerca />,
  },
  ////
  //// Softwares Routes
  {
    path: '/category/software',
    element: <ChangeUrl to='/soluciones-en-software-chile'/>,
  },
  {
    path: '/soluciones-en-software-chile',
    element: <Software />,
  },
  {
    path: '/software-pos-advance-chile',
    element: <POSAdvanceR />,
  },
  {
    path: '/erp-software-dimasoft-gestion-comercial-chile',
    element: <GComercialR />,
  },
  {
    path: '/erp-software-dimasoft-gestion-ferreterias-chile',
    element: <GFerreteriasR />,
  },
  {
    path: '/software-contabilidad-dimasoft-chile',
    element: <ContabilidadR />,
  },
  {
    path: '/software-remuneraciones-dimasoft-chile',
    element: <RemuneracionesR />,
  },
  {
    path: '/software-factura-electronica-dimasoft-chile',
    element: <FacturaElectronicaR />,
  },
  {
    path: '/boleta-electronica-dimasoft-chile',
    element: <BoletaElectronica />,
  },
  {
    path: '/software-e-commerce-erp-chile',
    element: <Ecommerce />,
  },
  ////
  //// Servicios Routes
  {
    path: '/category/servicios',
    element: <ChangeUrl to='/servicios-contables-TI-chile'/>,
  },
  {
    path: '/servicios-contables-TI-chile',
    element: <Servicios />,
  },
  {
    path: '/capacitaciones-dimasoft-chile',
    element: <CapacitacionesR />,
  },
  {
    path: '/servicios-ti-dimasoft-chile',
    element: <ServiciosTI />,
  },
  {
    path: '/servicio-contable-y-auditoria',
    element: <ContableAuditoria />,
  },

  {
    path: '/clientes',
    element: <ChangeUrl to='/clientes-dimasoft'/>,
  },
  {
    path: '/clientes-dimasoft',
    element: <Clientes />,
  },
  {
    path: '/distribuidores',
    element: <ChangeUrl to='/'/>,
  },
  // {
  //   path: '/distribuidores-dimasoft',
  //   element: <Distribuidores />,
  // },
  {
    path: '/contacto',
    element: <Contacto />,
  },
  
  {
    path: '/kit-punto-de-venta-pos',
    element: <KitPuntoVentaR />,
  },
  {
    path: '/politica-devoluciones-y-reembolsos',
    element: <PoliticaDevoluciones />,
  },
  
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <FooterGrupoDCT/>
    <Footer/>
  </React.StrictMode>,
)
