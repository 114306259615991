import './ContactButtons.css'
import { FaUser, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa'
import webpayImage from '../../../Others/webpay-dimasoft.webp'

export function LeftButtons(){
    return <div className='leftButtons'>

        <a className='client-access-left-mobil' href='https://dashboard-webapp.prod.dimasoft.cl/' target="_blank" alt="wsp button" aria-label="client"><FaUser /></a>
        <a className='wspButton' href='https://wa.me/56982945099' target="_blank" alt="wsp button" aria-label="wsp"><FaWhatsapp /></a>
        <a className='telButton' href="tel:+56222051018" target="_blank" alt="tel button" aria-label="telephone"><FaPhoneAlt /></a>
        <a className='wpButton' href="https://www.webpay.cl/company/25301" target="_blank" alt="webpay button" aria-label="webpay"><img src={webpayImage} alt="webpay" /></a>

    </div>
}