import { useEffect } from 'react';

export function TitleDescription({title, description=''}){

    const usePageMeta = (title, description) =>{
        const defaultTitle = "Dimasoft - Softwares chile";
        const defaultDesc = "En Dimasoft te ofrecemos los mejores Softwares, ERP y asesoramiento de Chile. Sistemas de contabilidad, Puntos de venta, Sofware de gestion y Facturación Electrónica.";
    
        useEffect(() => {
            document.title = title || defaultTitle;
            document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);
        }, [defaultTitle, title, defaultDesc, description]);
    };

    usePageMeta(title, description);

    return <></>
}