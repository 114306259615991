import logoGrupoDimasoft from '../../../Dimasoft/grupo_dimasoft_logo.webp'
import logoDimasoftDCT from '../../../Dimasoft/dimasoft_dct_logo.jpg'
import logoDimasoft from '../../../Dimasoft/logo-dimasoft-DCT.webp'
import logoSolutionOps from '../../../Socios/solutionops-dimasoft.webp'

export function FooterGrupoDCT(){
    
    return <div className='containerFooterGrupoDCT'>
        <div className='footerDCT'>
            <div className='footerDCTContact'>
                {/* <p className='footerTitle'>CONTÁCTENOS</p>
                <p className='footerText'><a href="mailto:info@grupodct.cl">info@grupodct.cl</a></p>
                <p className='footerText'><a href="#">Pocuro #2378 – Providencia - Santiago</a></p>
                <p className='footerText'><a href="tel:+56932392429" target='blank'>+56 9 3239 2429</a></p> */}
            </div>
            <div className='footerDCTLogos'>
                <div className='principal-logo'>
                    <a href="https://grupodct.cl" target='blank'>
                        <img 
                            loading="lazy" 
                            src={logoGrupoDimasoft} 
                            alt="logo_grupodct" 
                            width="400"
                            height="200"
                        />
                    </a>
                </div>
                <br />
                <div className='members-logo'>
                    <a href="https://www.dimasoft.cl" target='blank'>
                        <img 
                            loading="lazy" 
                            src={logoDimasoft} 
                            alt="logo_dimasoft" 
                            width="200"
                            height="100"
                        />
                    </a>
                    <a href="https://www.solutionops.cl" target='blank'>
                        <img 
                            className='solops-logo-footer'
                            loading="lazy" 
                            src={logoSolutionOps} 
                            alt="logo_solutionops"
                            style={{height:"90px"}}
                            width="200"
                            height="90"
                        />
                    </a>
                    <a href="https://grupodct.cl/" target='blank'>
                        <img 
                            className='dct-logo-footer'
                            loading="lazy" 
                            src={logoDimasoftDCT} 
                            alt="logo_grupodct"
                            style={{width:"160px", height:"55px"}}
                            width="160"
                            height="55"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
}
