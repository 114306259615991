import './contactenos.css'
import contactenosimg from '../../../Others/contacto-dimasoft.jpg'
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'
import { FormContact } from '../Contacto/Contacto.jsx'

export function Contactenos(){

    return <div className='containerContactenos'>
        <div className='contactenos-left' data-aos="fade-right">
            <p>Solicite una demostración personalizada, conversemos de su negocio…</p>
            <p><b>Informacíon de contacto</b></p>
            <p>+56 22 205 1018</p>
            <p><FaMapMarkerAlt/> Pocuro #2378, Of. 201, Providencia, Santiago</p>
            <p><FaEnvelope/> info@dimasoft.cl</p>
            <img src={contactenosimg} alt="Image Contacto" />
        </div>
        <div className='contactenos-right'>
            <FormContact/>
        </div>
    </div>
}