import './politicaDevoluciones.css'
import { FirstHeader, SecondHeader} from '../Header/Headers'
import { LeftButtons} from '../LeftButtons/ContactButtons'
import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../MetaTags/TitleDescriptions';

export function PoliticaDevoluciones(){

    return <div className='containerPoliticas'>
        <TitleDescription title='Acerca - Dimasoft' description='Conoce acerca de nosotros, nuestros inicios y nuestro enfoque.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <div className='politicas'>
            <h1>Política de Devoluciones y Reembolsos</h1>
            <div className='line gradient line-medium' data-aos="fade-right"></div>
            <div className='acercaContent'>
                <div className='politicasText'>
                    <p>
                        <strong><u>1.1. Derecho a Retracto.</u></strong> De acuerdo a la facultad que confiere el artículo 3 BIS de la Ley del Consumidor, el consumidor podrá poner término unilateralmente al contrato, sin expresión de causa, en el plazo de 10 días contados desde la recepción del producto en los siguientes casos:
                    </p>
                    <ol type="A">
                        <li>Compra de bienes y contratación de servicios en reuniones convocadas con dicho objetivo.</li>
                        <li>Contratos celebrados por medios electrónicos, y a través de catálogos.</li>
                        <li>Compras presenciales en que el consumidor no tuvo acceso directo al bien.<br/> <br/>
                            En el caso de servicios el proveedor puede disponer lo contrario, y en el caso de los bienes que por su naturaleza no puedan ser devueltos, se deterioren con rapidez, sean de uso personal o que han sido confeccionados según indicaciones del consumidor, no procederá el derecho a retracto.
                            <br/><br/>
                            El ejercicio del retracto será compatible con la Garantía Legal establecida en los Artículos 19 y 20 de la Ley. En conformidad a lo anterior, los clientes pueden ejercer, indistintamente, el derecho que estimen pertinente siempre y cuando se cumplan los requisitos y plazos legales correspondientes.

                        </li>
                    </ol>
                    <p>
                        <strong><u>1.2 Devolución de dinero.</u></strong> En caso de que corresponda realizar la restitución del dinero a nuestros clientes, por cualquier motivo o causal, Dimasoft Ltda. dispondrá para dicha gestión de un plazo de 15 días hábiles desde la emisión de la correspondiente nota de crédito.
                    </p>
                    <p>
                        En los casos de compras que se efectúan mediante tarjeta de crédito, ya sea bancaria o de casa comercial, se entenderá cumplida la gestión de restitución del cupo en la tarjeta de crédito respectiva una vez emitida la comunicación a la plataforma dispuesta por la entidad emisora de la tarjeta solicitando se procese la anulación de la compra. Sin perjuicio de lo anterior, si el cliente señala no haber recibido los fondos, se vuelve a insistir, este proceso se repetirá cuantas veces sea necesario. No obstante, el plazo de las devoluciones puede variar según lo dispuesto por la respectiva entidad emisora. 
                    </p>
                    <p>
                        En cuanto a devoluciones por compras efectuadas mediante tarjeta de débito, de casa comercial, efectivo, o bien, mediante transferencia electrónica, Dimasoft Ltda. se contactará con el cliente mediante correo electrónico o vía telefónica para comunicar la emisión de la respectiva nota de crédito junto con solicitar y confirmar los datos bancarios de la cuenta a la cual se realizará la transferencia, restitución que se realizará en el plazo señalado en el primer párrafo de esta cláusula. Tratándose de los medios de pago regulados en este párrafo la devolución se realizará, siempre cuándo el Rut corresponda al titular de la compra, a la cuenta informada por el cliente. Es responsabilidad del cliente informar con veracidad y precisión los datos de su cuenta. Dimasoft Ltda. no se responsabiliza por errores o equivocaciones por parte de los clientes al momento de entregar sus datos de transferencia.
                    </p>
                    <p>
                        En caso de que, por cualquier causal, no sea posible efectuar la devolución a la cuenta señalada anteriormente, Dimasoft Ltda. procederá a la emisión de un vale vista a nombre del comprador. Dicha situación será informada por correo electrónico al cliente.
                    </p>
                    <p>
                        Asimismo, en caso de pago mediante cheques se debe distinguir si estos fueron cobrados o no. Si los cheques fueron cobrados se devolverá su valor mediante transferencia bancaria. En caso contrario, de no haber sido cobrados, estos serán devueltos. Para efectos de ejecutar la transferencia bancaria Dimasoft Ltda. solicitará datos de transferencia. Si estos no son entregados procederá a la emisión de un vale vista a nombre del comprador.
                    </p>
                    <p>
                        Nunca enviaremos un link para solicitar datos bancarios ni solicitaremos, por ningún medio, claves o coordenadas bancarias. 
                    </p>
                    <p>
                        <strong><u>1.3 Terminación del Contrato.</u></strong> En caso de que el cliente desee poner término al contrato en virtud del ejercicio del derecho a retracto en conformidad al Artículo 3 BIS podrá comunicar su intención de poner término al contrato a través del mismo medio que empleó para celebrar el contrato. 
                    </p>
                    <p>
                        En caso de que el cliente desee poner término al contrato por cualquier otra causal distinta a la señalada en el párrafo anterior, deberá comunicar su decisión a través del formulario de contacto dispuesto en el sitio web de Dimasoft Ltda. <a href="https://dimasoft.cl/contacto">contacto</a>. En caso de existir una controversia, siempre podrás acudir al tribunal competente, de conformidad a las disposiciones de la Ley N°19.496. 
                    </p>
                    <p>
                        <strong><u>1.4 Beneficio Cambio de Producto.</u></strong> El Beneficio Cambio de Producto consiste en que todo cliente que haya cambiado de opinión respecto a la elección de su producto podrá sustituirlo por otro de su elección. Para hacer uso de este beneficio, el cliente se debe acercar dentro de los 10 días corridos posteriores a la compra o de recibido el producto, con aquel en excelentes condiciones, con su embalaje original completo y sellado, sin uso alguno, no haya sido abierto, conserve todos sus sellos originales, con todos sus accesorios y con los regalos promocionales que hayan estado asociados a la compra. Se entenderá que el equipo está en excelentes condiciones cuando, ya sea el producto o su embalaje, según corresponda, no contempla golpes, manchas, quiebres, daño estético de cualquier magnitud, softwares o programas adulterados o sin licencia, piezas reemplazadas, entre otros de similar naturaleza. Excluye equipos armados a la medida y productos de copia inmediata como así también aquellos con activación de códigos digitales.
                    </p>
                    <p>
                        Para hacer efectivo el Beneficio, el cliente debe presentar la Boleta o Factura o Guía de Despacho. No aplica para productos que hayan sido abiertos en control de calidad de la tienda y hayan sido aceptados conforme por el cliente. Dimasoft Ltda. se reserva el derecho a corroborar que se cumplan estos requisitos. 
                    </p>
                    <p>
                        En caso de que el cliente decida hacer uso del Beneficio Cambio de Producto y el nuevo producto elegido tenga un valor superior al inicialmente comprado, deberá abonar la diferencia de precio hasta completar el precio del nuevo producto. Por el contrario, si el nuevo producto elegido tiene un valor inferior al inicialmente comprado, Dimasoft Ltda. procederá a la devolución del dinero entregado en exceso en conformidad al punto 1.2. de estos Términos y Condiciones. 
                    </p>
                    <p>
                        El Beneficio Cambio de Producto no deroga ni reemplaza el Derecho a Retracto ni el Derecho a Garantía Legal, pudiendo el cliente elegir la figura jurídica que mejor estima protectora de sus derechos como consumidor. El Beneficio Cambio de Producto implica la sustitución del producto comprado por otro, es decir, no implica la terminación del contrato ni la devolución del dinero pagado, salvo que se haga aplicable lo señalado en el párrafo anterior.
                    </p>
                </div>
            </div>
        </div>
        <ScrollRestoration />
    </div>
}