import './contabilidad.css'
import { Footer} from '../../Footer/Footer'
import { FirstHeader, SecondHeader} from '../../Header/Headers'
import { LeftButtons} from '../../LeftButtons/ContactButtons'
import image1 from '../../../../Others/contabilidad.jpg'
import { Contactenos } from '../../Contactenos/Contactenos'

import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../../MetaTags/TitleDescriptions';

export function ContabilidadR(){
    return <div className='containerContabilidad'>
        <TitleDescription title='Sistema, Programa Contable. Software de Contabilidad - Dimasoft' description='Software de Contabilidad Chile. Sistema para controlar movimientos Contables en Chile. Multiempresa ahora en su versión Cloud.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <Contabilidad />
        <ScrollRestoration />
        {/* <Footer/> */}
    </div>
}
export function Contabilidad(){
    return <div className='contabilidad'>
        <h1>Contabilidad v7.0</h1>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gf-content-flex'>
            <div className='left-content'>
                <p>El software de Contabilidad V7.0 de Dimasoft se encuentra disponible para nuestros clientes en dos formatos de uso:</p>
                <ul>
                    <li>Versión Licencia – Red Área Local.</li>
                    <li>Versión Cloud – Pago Mensual.</li>
                </ul>
            </div>
            <img src={image1} alt="" />
        </div>
        <h2>Características:</h2>
        <div className='gf-content'>
            <ul data-aos="fade-right">
                <li>Software multiempresa, puede manejar hasta 999 empresas en línea</li>
                <li>Gran flexibilidad en la definición del plan de cuentas, permitiendo la creación de estructuras personalizadas o el uso de una plantilla</li>
                <li>Manejo de atributos de análisis de cuenta el cual permite tener mayor información para determinar la composición de las operaciones</li>
                <li>Conciliación Bancaria</li>
                <li>Manejo de múltiples impuestos</li>
                <li>Honorarios</li>
                <li>Asientos contables flexibles y fáciles de administrar</li>
                <li>Centralizaciones configurables</li>
                <li>Control de acceso de usuarios</li>
                <li>Bloqueos de periodos flexibles parciales o totales</li>
                <li>Renumeración de Asientos</li>
                <li>Contabilización de Compras/Ventas</li>
                <li>Importación de Libros de Compra y Venta desde SII.</li>
                <li>Centros de costo.</li>
            </ul>
        </div>
        <h2>Precios</h2>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gf-content'>
            <p data-aos="fade-right"><b>Precios Versión Licencia:</b> Desde $290.000.- + Iva.</p>
            <p data-aos="fade-right"><b>Precios Versión Cloud:</b> Desde 1 UF + Iva.   1 Usuario / Mes  ( Facturación mensual ).</p>
            <ul data-aos="fade-right">
                <li>Multiempresa.</li>
                <li>Soporte Telefónico Permanente incluido</li>
                <li>Capacitación Inicial Incluida</li>
                <li>Habilitación Inmediata</li>
                <li>Respaldo de Datos incluido.</li>
            </ul>
        </div>
        <h2>Contáctenos</h2>
        <div className='line gradient line-small'></div>
        <div className='gf-content'>
            <Contactenos/>
        </div>
    </div>
}