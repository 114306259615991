import React, {useRef, useCallback} from 'react';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import styled from 'styled-components';

const Slideshow = ({
		children
	}) => {
	const slideshow = useRef(null);
	const siguiente = useCallback(() => {
			// Obtenemos el primer elemento del slideshow.
			const primerElemento = slideshow.current.children[0];

			// Establecemos la transicion para el slideshow.
			slideshow.current.style.transition = `500ms ease-out all`;

			const tamañoSlide = slideshow.current.children[0].offsetWidth;

			// Movemos el slideshow
			slideshow.current.style.transform = `translateX(-${tamañoSlide}px)`;

			const transicion = () => {
				// Reiniciamos la posicion del Slideshow.
				slideshow.current.style.transition = 'none';
				slideshow.current.style.transform = `translateX(0)`;

				// Tomamos el primer elemento y lo mandamos al final.
				slideshow.current.appendChild(primerElemento);

				slideshow.current.removeEventListener('transitionend', transicion);
			}

			// Eventlistener para cuando termina la animacion.
			slideshow.current.addEventListener('transitionend', transicion);
	},[]);
	
	const anterior = () => {
			// Obtenemos el ultimo elemento del slideshow.
			const index = slideshow.current.children.length - 1;
			const ultimoElemento = slideshow.current.children[index];
			slideshow.current.insertBefore(ultimoElemento, slideshow.current.firstChild);
			
			slideshow.current.style.transition = 'none';
			const tamañoSlide = slideshow.current.children[0].offsetWidth;
			slideshow.current.style.transform = `translateX(-${tamañoSlide}px)`;
		
			setTimeout(() => {
				slideshow.current.style.transition = `500ms ease-out all`;
				slideshow.current.style.transform = `translateX(0)`;
			}, 30);
	}

	return (
		<ContenedorPrincipal>
			<ContenedorSlideshow ref={slideshow}>
				{children}
			</ContenedorSlideshow>
			<Controles>
				<Boton aria-label="boton_slider_left" onClick={anterior}><FaChevronLeft/></Boton>
				<Boton aria-label="boton_slider_rigth" derecho onClick={siguiente}><FaChevronRight/></Boton>
			</Controles>
		</ContenedorPrincipal>
	);
}

const ContenedorPrincipal = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const ContenedorSlideshow = styled.div`
	display: flex;
	flex-wrap: nowrap;
	height: 100%;
`;

const Slide = styled.div`
	min-width: 100%;	
	overflow: hidden;
	transition: .3s ease all;
	z-index: 4;
	position: relative;
	height: 100%;

	
	img {
		position: relative;
		width: 100%;
		height: 100%;
		margin-right: 100px;
		object-fit: cover;
	}
	@media (max-width: 1300px) and (min-width: 1000px){
		img {
			width: 115%;
			height: 100%;
			margin-left: -15%;
		}
	}

	@media (max-width: 1000px) and (min-width: 690px){
		img {
			width: 115%;
			margin-left: -15%;
			height: 100%;
		}
	}

	@media (max-width: 690px){
		img {
			width: 115%;
			margin-left: -15%;
			height: 100%;
		}
	}
`;

const Controles = styled.div`
	position: absolute;
	top: 0;
	z-index: 4;
	width: 100%;
	height: 100%;
	pointer-events: none;
`;

const Boton = styled.button`
	pointer-events: all;
	background: #000;
	border: none;
	cursor: pointer;
	width: 50px;
	height: 60px;
	text-align: center;
	position: absolute;
	top: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
    font-size: 30px;
    color: #fff;
	opacity: 0.4;
	&:hover {
		opacity: 1;
	} 
	@media (max-width: 690px){
		width: 30px;
		height: 40px;
		font-size: 20px;
	}
	${props => props.derecho ? 
        'border-top-left-radius: 5px; border-bottom-left-radius: 5px; right:0' : 
        'border-top-right-radius: 5px; border-bottom-right-radius: 5px; left: 0'}
	
`;
 
export { Slideshow, Slide };