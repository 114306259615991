import './boletaElectronica.css'
import boletaImg from '../../../../Others/boleta-electronica.png'
import { FaEnvelope, FaMapMarkerAlt, FaRegClock } from 'react-icons/fa'
import { Footer} from '../../Footer/Footer'
import { FirstHeader, SecondHeader} from '../../Header/Headers'
import { LeftButtons} from '../../LeftButtons/ContactButtons'
import { FormContact} from '../../Contacto/Contacto'

import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../../MetaTags/TitleDescriptions';

export function BoletaElectronica(){

    return <div className='containerBoleta'>
        <TitleDescription title='Emite Boletas Electrónicas sin coste por emision - Dimasoft' description='Software para Boleta Electrónica Chile. Sistema de Boleta Electrónica con requisitos acorde al SII. Sin costo por emision!'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <div className='boleta'>
            <h1>Boleta Electrónica</h1>
            <div className='line gradient line-medium' data-aos="fade-right"></div>
            <div className='boleta-content'>
                <div className='boleta-content-left'>
                    <FormContact/>
                </div>
                <div className='boleta-content-right'>
                    <h3>Características</h3>
                    <ul data-aos="fade-left">
                        <li>Provee todos los Requisitos Establecidos por el Servicio de Impuestos Internos</li>
                        <li>Una solución preparada para los cambios.</li>
                        <li>Boleta Electrónica, nueva generación</li>
                        <li>Sin Costos por Emisión</li>
                    </ul>
                    <img src={boletaImg} alt="Office" />
                    <h3>Informacíon de contacto</h3>
                    <p>Si tiene alguna duda o consulta, contáctenos,</p>
                    <p>+56 22 205 1018</p>
                    <p><FaMapMarkerAlt/> Pocuro #2378, Of. 201, Providencia, Santiago</p>
                    <p><FaEnvelope/> info@dimasoft.cl</p>
                    <br />
                    <h3>Horario de atención</h3>
                    <p><FaRegClock/> Lunes a viernes - 08:30 a 18:30</p>
                </div>
            </div>
        </div>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}