import './footer.css'
import footerImage from '../../../Footer/logo-webpay-plus-dimasoft.webp'
import {FaPhoneAlt, FaEnvelope, FaMapMarkerAlt} from 'react-icons/fa'

export function Footer(){

    const getYear = () => {
        const today = new Date();
        return today.getFullYear();
    }
    
    return <div className='containerFooter'>
        <div className='footer'>
            <div className='thirdFooter'>
                <p className='footerTitle'>CONTÁCTENOS A TRAVÉS DE:</p>
                <p className='footerText'><FaPhoneAlt className='footerIcon'/> +56 22 205 1018</p>
                <p className='footerText'><FaMapMarkerAlt className='footerIcon'/> Pocuro #2378, Of. 201, Providencia, Santiago</p>
                <p className='footerText'><FaEnvelope className='footerIcon'/> info@dimasoft.cl</p>
            </div>
            <div className='thirdFooter'>
                <p className='footerTitle'>MEDIOS DE PAGO</p>
                <p className='footerText'>Realiza tus pagos con tarjeta de crédito o débito a través de Webpay haciendo click en el siguiente <a href='https://www.webpay.cl/company/25301' target='blank'>enlace.</a></p>
                <a href="https://www.webpay.cl/company/25301" target='blank'><img src={footerImage} alt="webpay" style={{width:"130px", padding:"8px"}} width="130" height="60"/></a>
            </div>
            <div className='thirdFooter'>
                <p className='footerTitle'>ACERCA DE NOSOTROS</p>
                <p className='footerText'>Somos una empresa chilena. Iniciamos nuestras actividades el año 1992, orientándonos al servicio de desarrollo de software en el ámbito comercial, para entregar soluciones a pequeñas y medianas empresas, de manera que puedan contar con información completa y oportuna, condición indispensable para desarrollarse en mercados dinámicos y competitivos.</p>
            </div>
        </div>
        <div className='footerFooter'>
            <div className='footerLine'></div>
            <p>© {getYear()} Dimasoft</p>
        </div>
    </div>
}
