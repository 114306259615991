import './algunosClientes.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import * as allClients from '../../../../Clientes/clientsImg'


const clientes = [
    allClients['brinks'],
    allClients['burger'],
    allClients['decorata'],
    allClients['edifier'],
    allClients['codelco'],
    allClients['rossetti'],
    allClients['la_mendez'],
    allClients['d_williams'],
    allClients['america_dvd'],
    allClients['dimar'],
    allClients['armada'],
    allClients['pdi'],
    allClients['abaflex'],
    allClients['ecaso'],
    allClients['fernando_bravo'],
    ]

export function AlgunosClientes(){

    const responsive = {
        desktop: {
          breakpoint: { max: 4000, min: 1024 },
          items: 6
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
    }

    return <div className="containerAlgunosClientes">
        <div className='algunosClientes'>
            <p className="a-clientes-title">Algunos de nuestros clientes</p>
            <div className='linea'  data-aos="fade-right"></div>
            <div className='sliderClientes'  data-aos="fade-left">
                <Carousel responsive={responsive}>
                    {clientes.map(item=><img src={item} key={clientes.indexOf(item)} alt="client_image"/>)}
                </Carousel>
            </div>
        </div>
    </div>
}