import './informacion.css'
import bandera_chile from '../../../../Informacion/bandera-chile-dimasoft.webp'
import cronografo from '../../../../Informacion/cronografo-dimasoft.webp'
import soporte_dimasoft from '../../../../Informacion/soporte-dimasoft.webp'
import certificado from '../../../../Informacion/certificado-dimasoft.webp'

export function Informacion(){

    const cards = [
        {
            image: bandera_chile,
            title: 'Empresa Chilena',
            text: 'Conocemos las dificultades a las que se enfrentan nuestros clientes y por eso les brindamos soluciones reales y eficaces'
        },
        {
            image: cronografo,
            title: 'Desde 1992',
            text: 'Durante el transcurso de este tiempo hemos ganado la confianza de nuestros clientes debido al compromiso de colaboración'
        },
        {
            image: soporte_dimasoft,
            title: 'Soporte Integral',
            text: 'Nuestros clientes estarán protegidos y contarán con el respaldo de una empresa con experiencia en el área informática'
        },
        {
            image: certificado,
            title: 'Personal Altamente Calificado',
            text: 'Contamos con los mejores técnicos e ingenieros para brindar un servicio de primera a todos nuestros clientes'
        }
    ]

    return <div className="containerInformacion">
        <div className='informacion'>
            {cards.map((item, index) => 
                <div className='card-informacion' key={index} data-aos={window.screen.width<=425? index==0?"":"fade-left":"fade-left"} data-aos-delay={cards.indexOf(item)*100}>
                    <img src={item.image} alt={item.title}/>
                    <p className='informacion-title'>{item.title}</p>
                    <p className='informacion-text'>{item.text}</p>
                </div>)
            }
        </div>
    </div>
}